import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import { Heading2 } from "../components/common/ntmwd";
import { WmkSeo } from "wmk-seo";
const NotFoundPage = () => (
  <Container>
    <WmkSeo.Meta
      title="404"
      siteTitle="NTMWD"
      ogImageUrl={''}
      twitterHandle={''}
      twitterImageUrl={''}
      slug="/404"
      baseUrl="https://board.ntmwd.com"
    />
    Î
    <Row>
      <Col>
        <Heading2>404 Error</Heading2>
        <p>You just hit a route that doesn&#39;t exist.</p>
      </Col>
    </Row>
  </Container>
);

export default NotFoundPage;
